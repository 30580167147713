@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap');

@font-face {
  font-family: 'Avenir Next';
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  src: url('./fonts/avenir-next.woff2') format('woff2'),
  url('./fonts/avenir-next.woff') format('woff');
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  @layer responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}
